// import slugify from '@sindresorhus/slugify'
import { gql } from 'graphql-request';
import type {
	GetStaticPropsContext,
	NextPage,
	InferGetStaticPropsType,
} from 'next';
import Head from 'next/head';
import { renderMetaTags, useQuerySubscription } from 'react-datocms';
import tw from 'twin.macro';
import { createQuerySubscription } from '@datocms/api';
import {
	HERO_SECTION_FRAGMENT,
	LAYOUT_FRAGMENT,
	PAGE_SECTIONS_FRAGMENT,
	PAGE_SEO_FRAGMENT,
	SITE_SEO_FRAGMENT,
} from '@datocms/fragments';
import LivePreviewLayout from '@datocms/LivePreviewLayout';
import Hero from '@sections/Hero';
import PageSection from '@sections/index';

import usePageContext from 'hooks/usePageContext';

// required for Hash-based CSP to work with ISR on Vercel
export const config = {
	unstable_includeFiles: ['.next/static/chunks/**/*.js'],
};

const QUERY = gql`
	query HomepageQuery($locale: SiteLocale!) {
		${SITE_SEO_FRAGMENT}
    page: homePage(locale: $locale) {
      _modelApiKey
      _allSlugLocales {
				locale
				value
			}
      ${PAGE_SEO_FRAGMENT}
      title
			layout {
				${LAYOUT_FRAGMENT}
			}
      heroSection {
        ${HERO_SECTION_FRAGMENT}
      }
			pageSections {
				${PAGE_SECTIONS_FRAGMENT}
			}
		}
	}
`;

export const getStaticProps = async ({
	locale,
	preview,
}: GetStaticPropsContext) => {
	const isPreview = preview || process.env.NODE_ENV === 'development';
	const querySubscriptionData = await createQuerySubscription<any>({
		query: QUERY,
		variables: { locale },
		preview: isPreview,
	});

	const props = {
		querySubscriptionData,
	};

	return { props };
};
const Home: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
	querySubscriptionData,
}) => {
	const { data, ...connection } = useQuerySubscription(querySubscriptionData);
	const { favicon } = data.site;
	const { pageSections = [], heroSection, title, seo } = data.page;
	const { availableLocales, defaultLocale } = usePageContext(data);
	return (
		<>
			<Head>
				{availableLocales.map(([locale]) => (
					<link
						key={locale}
						rel="alternate"
						hrefLang={locale}
						href={`${process.env.NEXT_PUBLIC_SITE_URL}${
							locale !== defaultLocale ? `/${locale}` : ''
						}`}
					/>
				))}
				{renderMetaTags(seo.concat(favicon))}
			</Head>
			<LivePreviewLayout data={data} {...connection}>
				{heroSection?.[0] && (
					<Hero
						autoLinkTitle={true}
						dato={{
							...heroSection[0],
							...(!heroSection[0].title ? { title } : {}),
						}}
					/>
				)}

				{pageSections.map((typedSection, index) => (
					<PageSection
						key={typedSection.id}
						css={index % 2 === 0 ? tw`bg-white` : tw`bg-gray-50`}
						dato={typedSection}
					/>
				))}
			</LivePreviewLayout>
		</>
	);
};

export default Home;
